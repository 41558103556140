import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import CompEBan from "../components/CompEBan";

import pic09 from "../assets/images/compE/fog-school.jpg";
import pic10 from "../assets/images/compE/fog-language.jpg";
import pic11 from "../assets/images/compE/fog-lowfid.jpg";
import pic12 from "../assets/images/compE/museum-main.jpg";
import pic13 from "../assets/images/compE/museum-invite.jpg";
import pic14 from "../assets/images/compE/museum-artifact.jpg";

const Landing = (props) => (
  <Layout>
    <Helmet>
      <title>Competency E</title>
      <meta name="description" content="Competency E" />
    </Helmet>

    <CompEBan />

    <div id="main">
      <section id="intro">
        <div className="inner">
          <header className="major">
            <h2>Introduction</h2>
          </header>
          <p>
            Informatics professionals often work collaboratively in teams to solve user-centric information and data problems. In order to complete projects efficiently, teams should use project management methodologies to assign tasks to team members and monitor each member’s progress. Solving user-centric information and data problems can be a complex operation, and it’s important for teams to work together to create products that are usable and technically sound. As a UI designer and web developer, I have regularly worked on diverse teams to deliver new applications and services. Team diversity is an essential part of creating good products, and informaticists can help provide teams with data solutions based on user needs and requirements (Pfeffer, 2019).
          </p>
        </div>
      </section>
      <section id="overview">
        <div className="inner">
          <header className="major-black">
            <h2>Coursework Overview</h2>
          </header>
          <p>
            Throughout the course of this program, I’ve had many opportunities to work collaboratively with two of my classmates to solve user-centric information and data problems. In INFM 204, my classmates and I worked together to redesign an existing mobile application that informed parents in the central California region about foggy day school schedules for their children. In order to create a usable solution for our target audience, my team and I first researched our audience’s demographics and sent surveys out to potential users. This information helped us create a list of personas and discover possible solutions that could make the app more useful. We also tested the app’s prototype extensively with real users and worked together to write a report detailing our experiences. Additionally, in INFM 201, I worked together with two other classmates to create a prototype website and proposal for a medical museum. After we were given the parameters for this project, we used project management practices to assign different roles to each person on the team. Although we had separate roles, my team and I regularly shared updates and collaboratively brainstormed ideas to create a usable solution. In INFM 205, I also had the chance to discuss various project management practices with my classmates. My post focused on the elements of a scope statement, and the inevitable “scope creep” that can occur over a project’s timeline. Since scope statements and scope creep are influenced by team dynamics, it was interesting to learn about my classmates’ experiences with scope statements while working in teams to solve user-centric information and data problems.
          </p>

          <div className="grid-wrapper">
            <div className="col-4">
              <div className="box">
                <h3>Evidence 1</h3>
                <p>
                  INFM 204 Team Project: Redesigning an application for school foggy day schedule delays.
                </p>
                <a href="#two" className="button">
                  View Project
                </a>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <h3>Evidence 2</h3>
                <p>
                  INFM 201 Team Project: Creating a project proposal and website for a medical museum.
                </p>
                <a href="#three" className="button">
                  View Project
                </a>
              </div>
            </div>
            <div className="col-4">
              <div className="box">
                <h3>Evidence 3</h3>
                <p>
                  INFM 205 Group Discussion: Implementing project managment practices and defining scope for teams.
                </p>
                <a href="#three" className="button">
                  View Project
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="two" className="spotlights">
        <section>
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-6">
                <img src={pic09} alt="A person using the foggy day app on an iPhone" className="image fit" />
              </div>
              <div className="col-6">
                <header className="major">
                  <h3>
                    <i class="icon fa-chart-area"></i> Evidence 1: Redesigning an application for foggy day school schedules
                  </h3>
                </header>
                <p>
                  In INFM 204, my classmates and I were assigned a team project that involved creating a design or redesign of a new or existing product. My team chose to redesign the ValleyPBS Fogtracker app for iOS. This application serves the Central Valley region of California and notifies parents about school delays and cancellations due to low visibility driving conditions caused by tule fog. The ValleyPBS Fogtracker app originally showed a simple list of schools that had foggy day schedules, but failed to specify the length of the delays, and users could not search for specific schools. In order to redesign this app and improve user experience, my teammates and I sent out surveys, researched personas, and studied similar applications for inspiration.
                </p>
                <p>
                  My role in this project was to create the overall design of the app and the interactive prototype. Once our personas and user needs were identified, I sketched out a low-fidelity wireframe and got feedback from my team. Following this, I designed a high-fidelity wireframe and built an interactive prototype for the iPhone. Once the prototype was ready to be tested, my teammate invited people from her workplace to test it and give feedback. After receiving comments from the testers, I made some changes to the prototype and refined it. Our redesigned application improved the usability of the app, and users were able to view the foggy delay schedules of schools near their geolocation, search for individual schools, and view their local weather conditions. Redesigning an existing product was a new experience for me, and completing this project collaboratively helped me learn more about solving user-centric information problems for a diverse target audience.
                </p>
                <p>
                  <a href="https://xd.adobe.com/view/007a35eb-c879-46cb-9bdb-f4d4c2f6a674-5529/" target="_blank" className="button next">
                    Try the Prototype
                  </a>
                </p>
                <p>
                  <a href="https://drive.google.com/file/d/1c1hr8_L39PFAqpLOT1iAAJ5PZGcHFqRO/view?usp=sharing" target="_blank" className="button next">
                    Read the Project
                  </a>
                </p>
              </div>

              <div className="col-6">
                <div className="box">
                  <img src={pic10} alt="Example of language selection in the foggy day app" className="image fit" />
                  <p>
                    <i>Users can experience the app in english or spanish</i>
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="box">
                  <img src={pic11} alt="A paper containing wireframe sketches" className="image fit" />
                  <p>
                    <i>Preliminary low-fidelity wireframes for the redesign</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="three">
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-6">
                <header className="major">
                  <h3>Evidence 2: Creating a project proposal and website for a medical museum.</h3>
                </header>
                <p>
                  Information professionals often work in diverse teams to solve user-centric information and data problems. In INFM 201, I had the opportunity to work in a team with two other people to redesign a website and solve data problems. For this project, we were given a case study scenario for a medical museum that wanted to create a website for its collection, add user accounts to the website for museum members, and allow user accounts to accept or decline RSVP invitations for museum events. Our team created a project proposal that outlined the client’s concerns and needs, discussed data workflow analyses, designed website wireframes, and created usable Python code for the website’s RSVP functionality. For this project, my role was to create the website’s wireframes and write the Python code to manage user RSVPs. I designed both low and high-fidelity website wireframes and added functionality specifications for the low-fidelity designs. The wireframes were designed according to the client’s needs and fulfilled all requirements specified in the original case study scenario. Furthermore, the client also wanted their users to be able to accept or decline RSVPs for museum events through their user accounts on the museum’s website. I created a sample Python code snippet to fulfill this request, which could be added to the website during its development phase. Overall, this project helped me improve my understanding of solving user-centric information and data problems while working in teams.
                </p>
              </div>
              <div className="col-6">
                <img src={pic12} alt="Museum of Medicine website home page" className="image fit" />
                <p>
                  <a href="https://drive.google.com/file/d/1fh1N6MXKfIzFcoGej0-jHhXIaySo61nA/view?usp=sharing" target="_blank" className="button next">
                    View the Project
                  </a>
                </p>
              </div>
              <div className="col-6">
                <div className="box">
                  <img src={pic13} alt="Museum of medicine user account page" className="image fit" />
                  <p>
                    <i>User account invitation concept</i>
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="box">
                  <img src={pic14} alt="Museum of Medicine single artifact page" className="image fit" />
                  <p>
                    <i>An example of a museum artifact page</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="three">
          <div className="inner">
            <div className="grid-wrapper">
              <div className="col-12">
                <header className="major">
                  <h3>Evidence 3: Implementing project managment practices and defining scope for teams</h3>
                </header>
                <p>
                  Teams must implement project management practices in order to assign tasks to team members and efficiently finish projects. In INFM 205, I had the opportunity to discuss the process of creating a scope statement with my classmates. A scope statement is a preliminary general plan that defines the project’s objective, deliverables, milestones, technical requirements, limits and exclusions, and reviews with the customer (Larson & Gray, 2018). By clearly defining each of these items, teams will have a clear overview of the project’s purpose and deliverables during all phases of a project. As I researched scope statements, I discovered the term “scope creep”, which refers to the tendency of projects to inflate or expand over the course of their completion. Scope creep usually occurs because team members may want to change deliverables or add features to a product, which is a common occurrence while working collaboratively with teammates. As I discussed these issues with my classmates, many of them shared their own experiences working on projects with scope creep, and I learned about different ways they mitigated this circumstance in their own workplaces. After discussing this topic with my peers, I discovered that one of the best ways to prevent scope creep is for project managers to be transparent with teams about a project’s goals and finances. This experience helped me better understand how to use project management practices to create a scope statement and prevent scope creep.
                </p>
                <p>
                  <a href="https://drive.google.com/file/d/1vZnv_ShxXgf_MauYmX5xybv4iP_BOcj2/view?usp=sharing" target="_blank" className="button next">
                    Read the Discussion
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section id="conclusion">
        <div className="inner">
          <header className="major">
            <h2>Conclusion</h2>
          </header>
          <p>
            Working collaboratively in teams, combined with good project management strategies, is an effective way to solve user-centric information and data problems. In INFM 204, my team and I worked together to redesign an iPhone app that notified parents about school delays due to low visibility foggy weather. During this project, my group and I sent out surveys to potential users and formed a list of user requirements for our redesigned app. I designed low and high-fidelity wireframes plus an interactive prototype that fulfilled our user requirements, and our redesigned app received good feedback during its final testing phase. I also had the chance to work collaboratively with a team in INFM 201. For this project, we were given a case study for a medical museum that wanted a website created for its collection with added user account functionality. I designed low and high-fidelity wireframes, website specifications, and created Python code to manage user RSVPs. By working together, my team and I created a feasible and usable solution to solve the client’s information and data problems. In INFM 205, I discussed the project scope process and talked about scope creep, which happens when a project’s original deliverables and features are expanded over the course of a project. I received a lot of feedback on my post and learned about my classmates’ experiences with scope creep and how they combat it in their own workplaces. Overall, the skills I learned while completing these courses have helped me expand my knowledge about solving user-centric information and data problems while working on diverse teams.
          </p>
          <div className="box">
            <h3>References</h3>
            <p>
              Larson, E., & Gray, C. (2018). Project Management: The Managerial Process (7th ed). New York: Mcgraw-Hill Education.
            </p>
            <p>
              Pfeffer, M. (2019, April 16). Informatics is a Team Sport. HIMSS: https://www.himss.org/resources/informatics-team-sport
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default Landing;
