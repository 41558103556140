import React from 'react'

const CompEBan = (props) => (
    <section id="banner" className="style2 bg-image-compe">
        <div className="inner">
            <header className="major">
                <h1>Competency E</h1>
            </header>
            <div className="content">
                <p>Work collaboratively in teams and use project management practices effectively to solve user-centric information and data problems</p>
            </div>
        </div>
    </section>
)

export default CompEBan
